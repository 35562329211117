import styled from 'styled-components';

import typography from '../styles/typography';

const GenericParagraph = styled.p`
  ${typography.body}
  text-align: left;
`;

export default GenericParagraph;
