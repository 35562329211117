import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../styles/colors';
import typography from '../styles/typography';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
`;

const Textarea = styled.textarea`
  color: ${colors.n100WetNose};
  font-feature-settings:
    'liga' off,
    'clig' off;
  ${typography.body};
  width: 100%;
  padding: 12px;
  border: 1px solid ${colors.n40Wolf};
  background-color: ${colors.n10Bone};
  overflow-y: hidden;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: ${colors.utilityFocus};
  }
`;

const ExpandableTextbox = ({
  placeholder,
  initialHeight = 50,
  maxHeight = 200,
  className,
  value,
  onChange,
  name,
  onBlur,
  qaId,
}) => {
  const [height, setHeight] = useState(initialHeight);

  const handleInput = (e) => {
    const scrollHeight = e.target.scrollHeight;
    const newHeight = Math.min(scrollHeight, maxHeight);

    setHeight(newHeight < initialHeight ? initialHeight : newHeight);

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <Container className={className}>
      <Textarea
        placeholder={placeholder}
        rows="1"
        style={{ height }}
        onInput={handleInput}
        value={value}
        onChange={onChange}
        name={name}
        onBlur={onBlur}
        data-qa-id={`intake--${qaId}--textarea`}
      />
    </Container>
  );
};

ExpandableTextbox.propTypes = {
  initialHeight: PropTypes.number,
  maxHeight: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  qaId: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
};

ExpandableTextbox.defaultProps = {
  initialHeight: 50,
  maxHeight: 200,
  value: '',
  onChange: () => {},
  name: '',
};

export default ExpandableTextbox;
