import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../styles/colors';
import typography from '../styles/typography';

const TitleContainer = styled.div`
  margin: 40px 0;
  display: flex;
  flex: 1 0 0;
  align-items: center;
  color: ${colors.n100WetNose};
`;

const Emoji = styled.span`
  margin-right: 8px;
  font-size: 24px;
`;

const TitleText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const TitlePartMain = styled.span`
  ${typography.headerMedium};
  font-weight: 600;
  text-transform: uppercase;
`;

const TitlePartSubtitle = styled.span`
  ${typography.header};
  text-transform: uppercase;
`;

const SurveyTitle = ({ className, emoji, mainText, subtitleText }) => (
  <TitleContainer className={className}>
    {emoji && <Emoji>{emoji}</Emoji>}
    <TitleText>
      <TitlePartMain>{mainText}</TitlePartMain>
      <TitlePartSubtitle>{subtitleText}</TitlePartSubtitle>
    </TitleText>
  </TitleContainer>
);

SurveyTitle.propTypes = {
  className: PropTypes.string,
  emoji: PropTypes.string.isRequired,
  mainText: PropTypes.string.isRequired,
  subtitleText: PropTypes.string.isRequired,
};

export default SurveyTitle;
