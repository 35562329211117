import React, { useId } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import colors from '../styles/colors';
import typography from '../styles/typography';

const RadioGroup = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 25px;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${typography.body};
  color: ${colors.n100WetNose};
`;

const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  opacity: 0;
  position: absolute;
`;

const CustomRadio = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;

  /* Styles for the selected state */
  ${({ checked }) =>
    checked
      ? `
      background-color: #0F6BFF;
      &:before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: white;
        border-radius: 50%;
      }
    `
      : `
  /* Styles for the unselected state */
      background-color: #F3F3F3;
      border: 1px solid #CCCCCC;
      &:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        background-color: #F3F3F3;
        border-radius: 50%;
      }
    `}
  margin-right: 8px;
`;

// value is the selected value for the radio button group
// If no value, no radio buttons are selected

// Roving tab index navigation following https://www.w3.org/WAI/ARIA/apg/patterns/radio/examples/radio/

const RadioButton = ({ onChange, name, value, qaId }) => {
  const { t } = useTranslation();
  const uniqueId = useId();

  const handleKeyDown = (event) => {
    const currentIndex = value ? options.indexOf(value) : 0;

    let newOption;

    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowLeft': {
        const prevIndex = (currentIndex - 1 + options.length) % options.length;
        newOption = options[prevIndex];
        break;
      }

      case 'ArrowDown':
      case 'ArrowRight': {
        const nextIndex = (currentIndex + 1) % options.length;
        newOption = options[nextIndex];
        break;
      }

      case ' ':
      case 'Spacebar': {
        // Space selects the currently focused option
        newOption = options[currentIndex];
        break;
      }

      default:
        newOption = null;
    }

    if (newOption) {
      event.preventDefault();

      // Update value
      onChange(newOption);

      // Focus on correct radio label
      document.getElementById(`${uniqueId}-${newOption.toLowerCase()}`).focus();
    }
  };

  const options = t('components.radio-button', {
    returnObjects: true,
  });

  const tabIndex = (option, index) => {
    if (value === option || (!value && index === 0)) {
      return 0;
    } else {
      return -1;
    }
  };

  return (
    <RadioGroup>
      {options.map((option, index) => (
        <RadioLabel
          key={option}
          tabIndex={tabIndex(option, index)}
          onKeyDown={(event) => handleKeyDown(event)}
          id={`${uniqueId}-${option.toLowerCase()}`}
        >
          <HiddenRadio
            name={name}
            value={option}
            checked={value === option}
            onChange={(e) => onChange(e.target.value)}
            data-qa-id={`${qaId}--radio-button-${option.toLowerCase()}`}
            tabIndex={-1}
          />
          <CustomRadio checked={value === option} />
          {option}
        </RadioLabel>
      ))}
    </RadioGroup>
  );
};

RadioButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  qaId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default RadioButton;
