import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import typography from '../styles/typography';
import Button from './Button';
import PrimaryHeader from './PrimaryHeader';
import SubjectText from './SubjectText';
import UnorderedList from './UnorderedList';

const GuideLines = styled.p`
  ${typography.bodyMedium}
`;

const ButtonContainer = styled.div`
  align-self: flex-start;
`;

const VideoFormOverview = ({ onNext }) => {
  const { t } = useTranslation();
  return (
    <>
      <PrimaryHeader>
        {t('video-submission.overview-page.header')}
      </PrimaryHeader>
      <SubjectText>
        {t('video-submission.overview-page.description')}
      </SubjectText>
      <GuideLines>{t('video-submission.overview-page.guidelines')}</GuideLines>
      <UnorderedList
        items={t('video-submission.overview-page.guideline-list', {
          returnObjects: true,
        })}
      />
      <ButtonContainer>
        <Button
          text={t('video-submission.overview-page.button')}
          icon="iconArrowRightWhite"
          onClick={onNext}
          qaId="video-submission--get-started"
        />
      </ButtonContainer>
    </>
  );
};

VideoFormOverview.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default VideoFormOverview;
