import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import colors from '../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  background: ${colors.n10Bone};
  margin-top: 35px;
  @media (max-width: 1023px) {
    width: 100%;
  }
  width: 335px;
`;

const Text = styled.p`
  font-family: UntitledSansMedium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin: 0;
  color: ${colors.n100WetNose};
`;

const ProgressBarAndTextContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;

  p {
    margin: 0;
  }
`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 15px;
  background-color: ${colors.utilityInfoLight};
  border-radius: 100px;
`;

const Progress = styled.div`
  height: 100%;
  background-color: ${colors.coreBlueClue};
  width: ${({ progress }) => progress}%;
  transition: width 0.3s ease;
  border-radius: 100px;
`;

const VideoProgressBar = ({ progress }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Text>{t('components.video-progress-bar.text')}</Text>
      <ProgressBarAndTextContainer>
        <ProgressContainer>
          <Progress progress={progress} />
        </ProgressContainer>
        <p>{progress}%</p>
      </ProgressBarAndTextContainer>
    </Container>
  );
};

VideoProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default VideoProgressBar;
