import React from 'react';

import Routes from '../routes';
import GlobalStyle from './GlobalStyle';

const App = () => {
  return (
    <>
      <GlobalStyle />
      {Routes}
    </>
  );
};

export default App;
