import React, { Suspense } from 'react';

import { createRoot } from 'react-dom/client';

import '../utils/i18n';

import App from './App';

//Added Suspense here since translations are loaded async
//Docs said it was good to have a fallback to prevent error

document.addEventListener('turbo:load', () => {
  const root = createRoot(
    document.body.appendChild(document.createElement('div')),
  );
  root.render(
    <Suspense fallback="...loading">
      <App />
    </Suspense>,
  );
});
