import styled from 'styled-components';

import colors from '../styles/colors';
import Button from './Button';

const DestructiveButton = styled(Button)`
  background-color: ${colors.n10Bone};
  &:hover {
    background-color: ${colors.n20Sheepdog};
  }

  &:focus {
    background-color: ${colors.n10Bone};
  }

  span {
    color: ${colors.utilityDanger};
  }

  &:disabled span {
    color: ${colors.n70Weimaraner};
  }

  border: ${({ isLoading }) => (isLoading ? 'none' : 'none')};
`;

export default DestructiveButton;
