import { createGlobalStyle } from 'styled-components';

import colors from '../styles/colors';
import mediaQueries from '../styles/layout';
import typography from '../styles/typography';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "UntitledSans";
    src: url("${window.fontPaths.untitledSansWoff2}") format("woff2"),
         url("${window.fontPaths.untitledSansWoff}") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "UntitledSerif";
    src: url("${window.fontPaths.untitledSerifWoff2}") format("woff2"),
         url("${window.fontPaths.untitledSerifWoff}") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "UntitledSansMedium";
    src: url("${window.fontPaths.untitledSansMediumWoff2}") format("woff2"),
         url("${window.fontPaths.untitledSansMediumWoff}") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "UntitledSerifMedium";
    src: url("${window.fontPaths.untitledSerifMediumWoff2}") format("woff2"),
         url("${window.fontPaths.untitledSerifMediumWoff}") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
    font-family: "UntitledSans", "Roboto", sans-serif; /* Set the default fonts */
    font-size: 100%;
    background-color: ${colors.n00White};
  }

  body {
    background-color: ${colors.n00White}
    color: ${colors.n100WetNose}
    display: flex;
    min-height: 100vh;
    min-width: 100%
    width: 100%;
    height: auto;
    flex-direction: column;
    border-radius: 25px;
    padding-bottom: 80px;
    /* Apply to the whole body so we don't need to apply in the component repeatedly */
    font-feature-settings: 'liga' off, 'clig' off;
  }


  ${mediaQueries.forMobile} {
    body {
      align-items: flex-start;
      gap: 25px;
      margin: 0;
      
      @media (min-width: 370px) {
        padding: 0px 23px 80px 23px;
      }

      @media (min-width: 390px) {
        padding: 0px 30px 80px 30px;
      }

      @media (min-width: 414px) {
        padding: 0px 40px 80px 40px;
      }
    }
  }

  ${mediaQueries.forTabletUp} {
    body {
      align-items: center;
      gap: 40px;
      padding: 0px 150px 80px 150px;
    }
  }

  @media print {
    body {
      display: initial; /* Helps with Firefox printing problems */
      color: ${colors.n100WetNose}
      background-color: ${colors.n00White}
      padding: 0; /* Remove padding for print */
    }
  }

  .no-scroll {
    overflow: hidden;
  }

  /* Overrides for React Datepicker styling */

  .react-datepicker {
    border-radius: 20px;
    border: 1px solid ${colors.n40Wolf};

    &__input-container input {
      border: 1px solid ${colors.n40Wolf};
      background: ${colors.n10Bone};
      display: flex;
      padding: 14px 12px;
      align-items: flex-start;
      width: 335px;

      &:focus,
      &:active,
      &:focus-visible {
        outline: 4px solid ${colors.utilityFocus}; 
      }
    }

    &__triangle {
      display: none;
    }

    &__header {
      margin-top: 20px;
      border: none;
    }


    &__header, 
    &__current-month, 
    &__header__dropdown,
    &__header__dropdown--scroll {
      background-color: ${colors.n00White};
    }

    &__day {
      border-radius: 50%;
      ${typography.body};
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      margin: 8px;

        &:not([aria-disabled="true"]):hover {
        background-color: ${colors.secondaryWaterBowl} !important;
        border-radius: 50% !important;
        color: ${colors.coreBlueClue};
      } 
    }

    &__day-names {
      padding: 10px;
      border-top: 1px solid ${colors.n40Wolf};
    }

    &__day-name {
      margin: 10px;
      ${typography.body};
      color: ${colors.n50Greyhound};
    }

    &__day--selected, 
    &__day--keyboard-selected {
      background-color: ${colors.coreBlueClue};
      color: ${colors.n00White};
      ${typography.bodyMedium}
      text-align: center;
      border-radius: 50%;
      &:not([aria-disabled="true"]):hover {
        background-color: ${colors.coreBlueClue} !important;
        border-radius: 50% !important;
      } 
    }

    &__navigation {
      top: 25px;

      &--next {
        right: 20px;
      }
      
      &--previous {
        left: 20px;
      }
    }

    &__navigation-icon {

      &::before {
        border-width: 2px 2px 0 0;
      }
    }

    &__day--disabled  {

      &:hover {
        /* No hover effect */
      }
    }

    &__week {
      width: 330px;
    }
  }

  .react-datepicker-wrapper {
    margin-top: 5px;
  }

  .react-datepicker-popper {
    top: -6px !important;
    left: 4px !important;
  }

  h2.react-datepicker__current-month {
    ${typography.bodyMedium};
    margin-bottom: 20px;
  }

  /* Overrides for React Datepicker styling */

`;

export default GlobalStyle;
