import styled from 'styled-components';

// `styled-components` automatically assigns props as element attributes.
// When we pass props, we often do not want these applied as attributes

// This is intended to prevent errors like:
// Warning: React does not recognize the `pressedBorderColor` prop on a DOM element.
// If you intentionally want it to appear in the DOM as a custom attribute, spell it as
// lowercase `pressedbordercolor` instead. If you accidentally passed it from a parent
// component, remove it from the DOM element.

const styledWithPropsFiltered = (tag) =>
  styled(tag).withConfig({
    shouldForwardProp: (prop) => {
      // List of props that should not be forwarded to the DOM
      const forbiddenProps = [
        'textColor',
        'bgColor',
        'isLoading',
        'pressedBorderColor',
        'hoverBgColor',
        'borderColor',
        'fontSize',
        'fontWeight',
        'margin',
        'padding',
        // Add any other style props that shouldn't appear in the DOM
      ];

      return !forbiddenProps.includes(prop);
    },
  });

export default styledWithPropsFiltered;
