const invalidDomains = [
  'gail.com',
  'gmai.com',
  'gamil.com',
  'gmail.co',
  'gnail.com',
  'gmaill.com',
  'gmail.con',
  'gmail.xom',
  'gmaol.com',
  'gmal.com',
  'gmial.com',
  'gnail.con',
  'yahoo.con',
  'yhoo.com',
  'hotmail.con',
  'concast.net',
  'comast.net',
];

const isValidEmail = (email) => {
  const domain = email.split('@')[1]?.toLowerCase();
  return (
    email.includes('@') &&
    email.indexOf('@') === email.lastIndexOf('@') &&
    email.lastIndexOf('.') > email.indexOf('@') &&
    email.length > email.lastIndexOf('.') + 1 &&
    !email.includes('/') &&
    !email.includes(' ') &&
    !invalidDomains.includes(domain)
  );
};

const validateNumberWithDecimal = (value, fieldName, errors, errorMessage) => {
  const regex = /^\d+(\.\d{1})?$/;
  if (value && !regex.test(value)) {
    errors[fieldName] = errorMessage;
  }
  const numValue = parseFloat(value);
  if (value && numValue === 0) {
    errors[fieldName] = 'Value cannot be zero';
  }
};

const validateEmail = (email, errors, message) => {
  if (email && !isValidEmail(email)) {
    errors.email = message;
  }
};

// Validation for the Contact step
export const validateContact = (values, message) => {
  const errors = {};
  validateEmail(values.email, errors, message);
  return errors;
};

// Validation for the Dog Demographics step
export const validateDogDemographics = (values, errorText) => {
  const errors = {};
  const requiredFields = ['age', 'weight', 'breed', 'coatColor', 'coatLength'];
  const conditionalFields = ['coatTexture', 'coatCoarseness', 'doubleCoat'];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  validateNumberWithDecimal(values.age, 'age', errors, errorText.age);
  validateNumberWithDecimal(values.weight, 'weight', errors, errorText.weight);

  if (
    values.coatLength === 'Long' ||
    values.coatLength === 'Medium' ||
    values.coatLength === 'Short'
  ) {
    conditionalFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });
  }

  if (values.coatTexture === 'Other') {
    if (!values.otherCoatTexture || values.otherCoatTexture.trim() === '') {
      errors.otherCoatTexture = errorText.texture;
    }
  }

  if (values.coatCoarseness === 'Other') {
    if (
      !values.otherCoatCoarseness ||
      values.otherCoatCoarseness.trim() === ''
    ) {
      errors.otherCoatCoarseness = errorText.coarseness;
    }
  }

  return errors;
};

// Validation for the Health Status step
export const validateHealthStatus = (values, errorObject) => {
  const errors = {};
  const requiredFields = ['scheduledSurgery', 'previousSurgeries'];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (values.mobilityIssues?.length === 0) {
    errors.mobilityIssues = errorObject.mobility;
  }

  if (
    values.mobilityIssues?.includes('Other mobility issue') &&
    !values.otherMobilityDetails?.trim()
  ) {
    errors.mobilityIssues = errorObject['mobility-other'];
  }

  if (values.scheduledSurgery === 'Yes' && !values.scheduledSurgeryDate) {
    errors.scheduledSurgeryDate = errorObject.surgery;
  }

  if (
    values.previousSurgeries === 'Yes' &&
    (!values.previousSurgeriesDetails ||
      !values.previousSurgeriesDetails.trim())
  ) {
    errors.previousSurgeriesDetails = errorObject['previous-surgery'];
  }

  return errors;
};
