import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router';
import styled from 'styled-components';

import typography from '../styles/typography';
import AdditionalInfo from './AdditionalInfo';
import Button from './Button';
import FormElementLabel from './FormElementLabel';
import GenericParagraph from './GenericParagraph';
import PrimaryHeader from './PrimaryHeader';
import SubjectText from './SubjectText';
import TextInput from './TextInput';

const BoldText = styled.span`
  ${typography.bodyMedium}
`;

const StyledAdditionalInfo = styled(AdditionalInfo)`
  margin: 10px auto 35px auto;
`;

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const formikProps = useOutletContext();
  const { t } = useTranslation();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    onNext,
    setFieldError,
  } = formikProps;

  const isEmailValid = values.email && !errors.email && touched.email;

  const handleNext = async () => {
    if (!isEmailValid) return;

    setIsLoading(true);
    try {
      await onNext(values, setFieldError);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <PrimaryHeader>{t('intake.contact.header')}</PrimaryHeader>
      <SubjectText>{t('intake.contact.study-description')}</SubjectText>
      <GenericParagraph>
        {t('intake.contact.email-description')}
        <BoldText>{t('intake.contact.support-email')}</BoldText>.
      </GenericParagraph>
      <GenericParagraph>
        {t('intake.contact.whitelist-instructions')}
      </GenericParagraph>
      <FormElementLabel
        labelText={t('intake.contact.email-label')}
        fieldFor="email"
        fieldId="email"
      />
      <TextInput
        name="email"
        inputText={t('intake.contact.input-placeholder')}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.email && errors.email}
        hasError={!!(touched.email && errors.email)}
      />

      <StyledAdditionalInfo
        additionalInfoText={t('intake.contact.whistle-instructions')}
      />
      <Button
        text={t('intake.continue-button')}
        onClick={handleNext}
        isLoading={isLoading}
        disabled={!isEmailValid}
        qaId="intake--contact-continue"
      />
    </div>
  );
};

export default Contact;
