import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router';
import styled from 'styled-components';

import colors from '../styles/colors';

const StyledContainer = styled.div`
  background: ${colors.n00White};
  background-size: 40px 40px;
  background-position: -19px -19px;
  min-height: 100vh;
`;

const ExpertSurveys = () => {
  const { expertId } = useParams();
  const [expertSurveys, setExpertSurveys] = useState([]);

  useEffect(() => {
    const url = `/api/v1/surveys/${expertId}`;
    fetch(url)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Network response was not ok.');
      })
      .then((res) => setExpertSurveys(res))
      .catch(() => alert('not found'));
  }, [expertId]);

  useEffect(() => {
    console.log(expertSurveys);
  }, [expertSurveys]);

  return (
    <StyledContainer data-qa-id="expert-reviews--survey-index--page">
      <h1>Expert Surveys page</h1>
    </StyledContainer>
  );
};

export default ExpertSurveys;
