import React, { useEffect, useState } from 'react';

import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import typography from '../styles/typography';
import Uploader from '../utils/uploader';
import Button from './Button';
import GenericParagraph from './GenericParagraph';
import PrimaryHeader from './PrimaryHeader';
import StyledImage from './StyledImage';
import VideoProgressBar from './VideoProgressBar';
import VideoUpload from './VideoUpload';

const GuideLines = styled.p`
  ${typography.bodyMedium}
`;

const ButtonContainer = styled.div`
  align-self: flex-start;
  margin-top: 40px;
`;

const SideToSideForm = ({
  onNext,
  sideToSideId,
  setSideToSideId,
  handleSubmit,
  setError,
}) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(false);
  const [file, setFile] = useState(0);

  useEffect(() => {
    // File has uploaded successfully, so form can be submitted
    if (sideToSideId) {
      handleSubmit(values);
      onNext();
    }
  }, [sideToSideId, values]);

  const uploader = new Uploader(
    file,
    '/rails/active_storage/direct_uploads',
    t('errors', {
      returnObjects: true,
    }),
  );

  // Handle direct upload
  const handleDirectUploadAndSubmit = () => {
    setUploading(true);
    setProgress(0);

    // Attach progress handler
    uploader.onProgress = (event) => {
      const percent = Math.round((event.loaded / event.total) * 100);
      setProgress(percent);
    };

    // Start the upload
    uploader.uploadFile(({ success, error, blob }) => {
      setUploading(false);

      if (success) {
        setSideToSideId(blob.signed_id); // This state change triggers a useEffect to submit the form
      } else {
        setError(error);
      }
    });
  };

  return (
    <>
      <PrimaryHeader>{t('video-submission.side-to-side.header')}</PrimaryHeader>
      <GenericParagraph>
        {t('video-submission.side-to-side.description')}
      </GenericParagraph>
      <GuideLines>{t('video-submission.example-text')}</GuideLines>
      <StyledImage
        src={window.imagePaths.sideToSideGif}
        alt={t('video-submission.side-to-side.gif-alt')}
      />
      <GenericParagraph>
        {t('video-submission.general-instructions', { walk: 'side to side' })}
      </GenericParagraph>
      <GenericParagraph>
        {t('video-submission.time-instructions')}
      </GenericParagraph>
      <GuideLines>{t('video-submission.your-submission')}</GuideLines>
      <VideoUpload file={file} setFile={setFile} setErrorMessage={setError} />
      {uploading ? (
        <VideoProgressBar progress={progress} />
      ) : (
        <ButtonContainer>
          <Button
            text={t('video-submission.upload-button')}
            isLoading={uploading}
            onClick={handleDirectUploadAndSubmit}
            disabled={!file}
            qaId="video-submission--continue-side-to-side"
          />
        </ButtonContainer>
      )}
    </>
  );
};

SideToSideForm.propTypes = {
  onNext: PropTypes.func.isRequired,
  setSideToSideId: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  sideToSideId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default SideToSideForm;
