const colors = {
  //Primary Colors
  coreBlueprint: 'rgb(18, 60, 157)',
  coreBlueClue: 'rgb(15, 107, 255)',
  coreCinnamon: 'rgb(202, 79, 39)',
  coreTabby: 'rgb(218, 108, 7)',

  // Secondary colors
  secondarySky: 'rgb(181, 213, 255)',
  secondaryWaterBowl: 'rgb(238, 248, 255)',
  secondaryPinkSand: 'rgb(247, 206, 188)',
  secondaryWarmWhite: 'rgb(249, 240, 230)',
  secondarySand: 'rgb(231, 206, 181)',

  // neutral scale
  n100WetNose: 'rgb(16, 16, 17)',
  n70Weimaraner: 'rgb(99, 99, 100)',
  n50Greyhound: 'rgb(140, 140, 140)',
  n40Wolf: 'rgb(204, 204, 204)',
  n20Sheepdog: 'rgb(243, 243, 243)',
  n10Bone: 'rgb(246, 246, 246)',
  n00White: 'rgb(255, 255, 255)',
  n100WetNoseLightened: 'rgb(90, 90, 96)',

  // utility colors
  utilityDanger: 'rgb(188, 36, 36)',
  utilityDangerLight: 'rgb(255, 170, 170)',
  utilityInfoLight: 'rgb(181, 213, 255)',
  utilitySuccess: 'rgb(42, 110, 13)',
  utilitySuccessLight: 'rgb(175, 235, 150)',
  utilityWarning: 'rgb(224, 164, 26)',
  utilityWarningLight: 'rgb(243, 220, 163)',
  utilityFocus: 'rgb(108, 160, 249)',
  utilityPrimaryHover: 'rgb(54, 131, 255)',
  communicationDanger: 'rgb(188, 36, 36)',
};

export default colors;
