import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../styles/colors';
import typography from '../styles/typography';
import ExpandableTextbox from './ExpandableTextbox';

const SelectContainer = styled.label`
  display: flex;
  width: '262px';
  padding: 10px 20px;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  border-radius: 10px;
  background: ${({ isSelected }) =>
    isSelected ? colors.secondaryWaterBowl : colors.n10Bone};
  border: ${({ isSelected }) =>
    isSelected ? `1px solid ${colors.coreBlueClue}` : '2px solid transparent'};
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 110px;
  overflow: hidden;
`;

const TextSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const RadioImage = styled.div`
  width: 110px;
  height: 110px;
  flex: 0 0 110px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

const RadioInput = styled.input`
  display: none;
`;

const RadioText = styled.span`
  ${typography.body};
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
  text-align: left;
  width: 100%;
  margin-top: ${({ isOther, fullWidthOtherInput }) =>
    isOther && fullWidthOtherInput ? '10px' : '0'};
`;

const DogCoatSelector = ({
  className,
  isSelected,
  onChange,
  label,
  image,
  isOther = false,
  fullWidthOtherInput = false,
  name,
  onOtherInputChange,
  otherInputValue,
  otherInputPlaceholder = 'Describe details',
  qaId,
}) => (
  <SelectContainer
    isSelected={isSelected}
    onClick={onChange}
    className={className}
    data-qa-id={`intake--dog-coat-selector-${qaId}`}
  >
    <RadioInput type="radio" checked={isSelected} onChange={onChange} />
    <ContentWrapper>
      <TextSection>
        <RadioText
          isSelected={isSelected}
          fullWidthOtherInput={fullWidthOtherInput}
          isOther={isOther}
        >
          {label}
        </RadioText>
        {isOther && isSelected && fullWidthOtherInput && (
          <ExpandableTextbox
            placeholder={otherInputPlaceholder}
            initialHeight={50}
            name={name}
            value={otherInputValue}
            onChange={onOtherInputChange}
            qaId={`intake--${qaId}`}
          />
        )}
      </TextSection>
      {!isOther && (
        <RadioImage>
          {image ? <img src={image} alt={label} /> : null}
        </RadioImage>
      )}
    </ContentWrapper>
  </SelectContainer>
);

DogCoatSelector.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isOther: PropTypes.bool,
  fullWidthOtherInput: PropTypes.bool,
  name: PropTypes.string,
  onOtherInputChange: PropTypes.func,
  otherInputValue: PropTypes.string,
  otherInputPlaceholder: PropTypes.string,
  qaId: PropTypes.string.isRequired,
};

DogCoatSelector.defaultProps = {
  image: '',
  isOther: false,
  fullWidthOtherInput: false,
  name: '',
  onOtherInputChange: () => {},
  otherInputValue: '',
  otherInputPlaceholder: 'Describe Detail',
};

export default DogCoatSelector;
