import styled from 'styled-components';

import colors from '../styles/colors';
import Button from './Button';

const TertiaryButton = styled(Button)`
  background-color: transparent;
  &:hover {
    background-color: ${colors.n20Sheepdog};
  }
  span {
    color: ${colors.coreBlueClue};
  }
`;

export default TertiaryButton;
