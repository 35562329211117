import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../styles/colors';

const StyledOverlayModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const OverlayBackground = styled.div`
  background-color: #2b2b2b;
  opacity: 0.24;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
`;

const OverlayContent = styled.div`
  background-color: ${colors.n00White};
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 16px 28px 16px;
  width: fit-content;
  height: fit-content;
  align-items: center;
  gap: 12px;
  border-radius: 20px;
  z-index: 11;
  box-shadow:
    0px 0px 4px 0px rgba(16, 16, 17, 0.1),
    0px 10px 20px -6px rgba(16, 16, 17, 0.15);
`;

const OverlayModal = ({ onClose, children, qaId }) => {
  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <StyledOverlayModal data-qa-id={`overlay-modal--${qaId}`}>
      <OverlayBackground onClick={onClose} />
      <OverlayContent>{children}</OverlayContent>
    </StyledOverlayModal>
  );
};

OverlayModal.propTypes = {
  children: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  qaId: PropTypes.string.isRequired,
};

export default OverlayModal;
