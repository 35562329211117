import React from 'react';

import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router';
import styled from 'styled-components';

import typography from '../styles/typography';
import AdditionalInfo from './AdditionalInfo';
import FancyUnorderedList from './FancyUnorderedList';
import GenericParagraph from './GenericParagraph';
import PrimaryHeader from './PrimaryHeader';
import SubjectText from './SubjectText';
import TertiaryButton from './TertiaryButton';

const StyledPrimaryHeader = styled(PrimaryHeader)`
  margin-bottom: 35px;
`;

const StyledGenericParagraph = styled(GenericParagraph)`
  margin: 20px 0;
`;

const MoreSpaceGenericParagraph = styled(GenericParagraph)`
  margin: 35px 0;
`;

const StyledAdditionalInfo = styled(AdditionalInfo)`
  text-align: center;
  p {
    ${typography.bodyMedium};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
`;

const ThankYou = () => {
  const formikProps = useOutletContext();
  const { handleNewSurvey } = formikProps;
  const { t } = useTranslation();
  return (
    <>
      <StyledPrimaryHeader>{t('intake.thank-you.header')}</StyledPrimaryHeader>
      <SubjectText>{t('intake.thank-you.device-and-panel')}</SubjectText>
      <StyledGenericParagraph>
        {t('intake.thank-you.email-instruction')}
      </StyledGenericParagraph>
      <FancyUnorderedList
        items={t('intake.thank-you.list', { returnObjects: true })}
      />
      <MoreSpaceGenericParagraph>
        {t('intake.thank-you.thank-you')}
      </MoreSpaceGenericParagraph>
      <StyledAdditionalInfo
        additionalInfoText={t('intake.thank-you.return-tablet')}
      />
      <ButtonContainer>
        <TertiaryButton
          qaId="intake--start-new-survey"
          onClick={handleNewSurvey}
          text={t('intake.thank-you.button')}
        />
      </ButtonContainer>
    </>
  );
};

export default ThankYou;

// /** Todo: redirect intake to contact. 2hours inactive, reset form, back to contact page*/
