import axios from 'axios';

// An workaround to get the base URL of the API
const getAPIBaseUrl = () => {
  return `${window.location.protocol}//${window.location.host}/api/v1`;
};

const api = axios.create({
  baseURL: getAPIBaseUrl(),
  timeout: 10000, // Optional: request timeout in milliseconds
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add interceptors for requests or responses if needed
api.interceptors.request.use(
  (config) => {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content');
    if (csrfToken) {
      config.headers['X-CSRF-Token'] = csrfToken;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default api;
