import React from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import GenericParagraph from '../components/GenericParagraph';
import PrimaryHeader from '../components/PrimaryHeader';

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const DogImage = styled.img`
  width: 280px;
  height: 280px;
`;

const SubmissionReceived = () => {
  const { t } = useTranslation();
  return (
    <div data-qa-id="video-submission--submission-received">
      <PrimaryHeader>
        {t('video-submission.submission-received.header')}
      </PrimaryHeader>
      <GenericParagraph>
        {t('video-submission.submission-received.thank-you')}
      </GenericParagraph>
      <ImageContainer>
        <DogImage
          src={window.imagePaths.celebrationDog}
          alt={t('video-submission.submission-received.img-alt')}
        />
      </ImageContainer>
    </div>
  );
};

export default SubmissionReceived;
