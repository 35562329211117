import { DirectUpload } from '@rails/activestorage';

import { errorByStatus } from '../utils/errors';

export default class Uploader {
  constructor(file, url) {
    this.upload = new DirectUpload(file, url, this);
    this.onProgress = null;
  }

  uploadFile(callback) {
    this.upload.create((error, blob) => {
      if (error) {
        // error is `Error creating Blob for "${this.file.name}". Status: ${this.status}`
        // See https://github.com/rails/rails/issues/49104
        const splitByStatus = error.split('Status: ');
        const status = splitByStatus[1];

        callback({
          success: false,
          error: errorByStatus(status),
        });
      } else {
        callback({ success: true, blob });
      }
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', (event) =>
      this.directUploadDidProgress(event),
    );
  }

  directUploadDidProgress(event) {
    this.onProgress && this.onProgress(event);
  }
}
