import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router';
import styled from 'styled-components';

import colors from '../styles/colors';

const StyledContainer = styled.div`
  background: ${colors.n00White};
  background-size: 40px 40px;
  background-position: -19px -19px;
  min-height: 100vh;
`;

const ExpertReviewForm = () => {
  const { surveyId, weekNumber } = useParams();
  const [weekToReview, setWeekToReview] = useState({});

  useEffect(() => {
    const url = `/api/v1/weekly-forms/survey/${surveyId}/week/${weekNumber}`;
    fetch(url)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Network response was not ok.');
      })
      .then((res) => setWeekToReview(res))
      .catch(() => alert('not found'));
  }, [surveyId, weekNumber]);

  useEffect(() => {
    console.log(weekToReview);
  }, [weekToReview]);

  return (
    <StyledContainer data-qa-id="expert-reviews--survey-review--page">
      <h1>Expert Surveys page</h1>
    </StyledContainer>
  );
};

export default ExpertReviewForm;
