import styled from 'styled-components';

import colors from '../styles/colors';
import typography from '../styles/typography';

const ErrorText = styled.label`
  padding-left: 12px;
  color: ${colors.communicationDanger};
  ${typography.bodyXSm};
`;
export default ErrorText;
