import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Image = styled.img`
  width: 329px;
  height: 220px;
  flex-shrink: 0;
`;

const StyledImage = (props) => <Image src={props.src} alt={props.alt} />;

StyledImage.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

export default StyledImage;
