import React, { useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { formatQaId } from '../utils/formatters';
import DogCoatSelector from './DogCoatSelector';

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
`;

const OtherOptionWrapper = styled.div`
  ${({ isOther, fullWidthOtherInput }) =>
    isOther &&
    fullWidthOtherInput &&
    `
    grid-column: span 2;
  `}
`;

const DogCoatOptions = ({
  className,
  onChange,
  options,
  selectedOption,
  fullWidthOtherInput = false,
  onOtherInputChange,
  otherInputValue,
  name,
  otherInputPlaceholder = 'Describe details',
}) => {
  const [localSelectedOption, setLocalSelectedOption] =
    useState(selectedOption);

  const handleOptionChange = (option) => {
    setLocalSelectedOption(option);
    onChange(option);
  };

  const handleOtherInputChange = (e) => {
    const value = e.target.value;
    onOtherInputChange(value);
  };

  return (
    <OptionsContainer className={className}>
      {options.map((option) => (
        <OtherOptionWrapper
          key={option.label}
          isOther={option.label === 'Other'}
          fullWidthOtherInput={
            fullWidthOtherInput && localSelectedOption === 'Other'
          }
        >
          <DogCoatSelector
            fullWidthOtherInput={
              fullWidthOtherInput && option.label === 'Other'
            }
            label={option.label}
            image={window.imagePaths[option.image]}
            isSelected={localSelectedOption === option.label}
            onChange={() => handleOptionChange(option.label)}
            isOther={option.label === 'Other'}
            name={name}
            onOtherInputChange={handleOtherInputChange}
            otherInputValue={otherInputValue}
            otherInputPlaceholder={otherInputPlaceholder}
            qaId={formatQaId(option.label)}
          />
        </OtherOptionWrapper>
      ))}
    </OptionsContainer>
  );
};

DogCoatOptions.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedOption: PropTypes.string,
  fullWidthOtherInput: PropTypes.bool,
  onOtherInputChange: PropTypes.func,
  otherInputValue: PropTypes.string,
  otherInputPlaceholder: PropTypes.string,
  name: PropTypes.string,
};

DogCoatOptions.defaultProps = {
  selectedOption: null,
  fullWidthOtherInput: false,
  onOtherInputChange: () => {},
  otherInputValue: '',
  name: '',
  otherInputPlaceholder: 'Describe details',
};

export default DogCoatOptions;
