import React, { useState } from 'react';

import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import typography from '../styles/typography';
import Uploader from '../utils/uploader';
import Button from './Button';
import FormElementLabel from './FormElementLabel';
import GenericParagraph from './GenericParagraph';
import PrimaryHeader from './PrimaryHeader';
import RadioButton from './RadioButton';
import StyledImage from './StyledImage';
import VideoProgressBar from './VideoProgressBar';
import VideoUpload from './VideoUpload';

const GuideLines = styled.p`
  ${typography.bodyMedium}
`;

const ButtonContainer = styled.div`
  align-self: flex-start;
  margin-top: 40px;
`;

const StyledFormLabel = styled(FormElementLabel)`
  margin-top: 40px;
  margin-bottom: 10px;
`;

const DownAndBackForm = ({ onNext, setDownAndBackId, setError }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(false);
  const [file, setFile] = useState(0);

  const handleIsOnPainMedsChange = (value) => {
    setFieldValue('isOnPainMeds', value);
  };

  const handleIsSedatedChange = (value) => {
    setFieldValue('isSedated', value);
  };

  const uploader = new Uploader(file, '/rails/active_storage/direct_uploads');

  // Handle direct upload
  const handleDirectUpload = () => {
    setUploading(true);
    setProgress(0);

    // Attach progress handler
    uploader.onProgress = (event) => {
      const percent = Math.round((event.loaded / event.total) * 100);
      setProgress(percent);
    };

    // Start the upload
    uploader.uploadFile(({ success, error, blob }) => {
      setUploading(false);

      if (success) {
        setDownAndBackId(blob.signed_id); // Store the signed ID for further use
        onNext();
      } else {
        setError(error);
      }
    });
  };

  return (
    <>
      <PrimaryHeader>
        {t('video-submission.down-and-back.header')}
      </PrimaryHeader>
      <GenericParagraph>
        {t('video-submission.down-and-back.description')}
      </GenericParagraph>
      <GuideLines>{t('video-submission.example-text')}</GuideLines>
      <StyledImage
        src={window.imagePaths.downAndBackGif}
        alt={t('video-submission.down-and-back.gif-alt')}
      />
      <GenericParagraph>
        {t('video-submission.general-instructions', { walk: 'down and back' })}
      </GenericParagraph>
      <GenericParagraph>
        {t('video-submission.time-instructions')}
      </GenericParagraph>
      <GuideLines>{t('video-submission.your-submission')}</GuideLines>
      <VideoUpload file={file} setFile={setFile} setError={setError} />
      <StyledFormLabel
        labelText={t('video-submission.down-and-back.pain-med-label')}
        fieldFor="isOnPainMeds"
        fieldId="isOnPainMeds"
      />
      <RadioButton
        name="isOnPainMeds"
        qaId="video-submission--pain-medication"
        value={values.isOnPainMeds}
        onChange={handleIsOnPainMedsChange}
      />
      <StyledFormLabel
        labelText={t('video-submission.down-and-back.sedative-med-label')}
        fieldFor="isSedated"
        fieldId="isSedated"
      />
      <RadioButton
        name="isSedated"
        qaId="video-submission--sedative-medication"
        value={values.isSedated}
        onChange={handleIsSedatedChange}
      />
      {uploading ? (
        <VideoProgressBar progress={progress} />
      ) : (
        <ButtonContainer>
          <Button
            text={t('video-submission.upload-button')}
            isLoading={uploading}
            onClick={handleDirectUpload}
            disabled={!values.isOnPainMeds || !values.isSedated || !file}
            qaId="video-submission--continue-down-and-back"
          />
        </ButtonContainer>
      )}
    </>
  );
};

DownAndBackForm.propTypes = {
  onNext: PropTypes.func.isRequired,
  setDownAndBackId: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

export default DownAndBackForm;
