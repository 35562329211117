import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router';
import styled from 'styled-components';

import DogCoatOptions from '../components/DogCoatOptions';
import GenericParagraph from '../components/GenericParagraph';
import TextInput from '../components/TextInput';
import Button from './Button';
import ErrorText from './ErrorText';
import FormElementLabel from './FormElementLabel';
import PrimaryHeader from './PrimaryHeader';

const StyledGenericParagraph = styled(GenericParagraph)`
  margin: 35px 0;
`;

const StyledWrapperWithErrorText = styled.div`
  margin-top: 13px;
`;

const StyledSectionWrapper = styled.div`
  margin-top: 35px;
`;

const DogDemographics = () => {
  const formikProps = useOutletContext();
  const { t } = useTranslation();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    isValid,
    dirty,
    validateForm,
    onNext,
  } = formikProps;

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const [showAdditionalCoatOptions, setShowAdditionalCoatOptions] = useState(
    values.coatLength === 'Long' ||
      values.coatLength === 'Medium' ||
      values.coatLength === 'Short',
  );

  useEffect(() => {
    setShowAdditionalCoatOptions(
      values.coatLength === 'Long' ||
        values.coatLength === 'Medium' ||
        values.coatLength === 'Short',
    );
  }, [values.coatLength]);

  useEffect(() => {
    // Disable continue button if coatTexture is 'Other' and otherCoatTexture is empty
    if (values.coatTexture === 'Other' && !values.otherCoatTexture.trim()) {
      validateForm(); // This will trigger form validation
    }
  }, [values.coatTexture, values.otherCoatTexture, validateForm]);

  useEffect(() => {
    // Disable continue button if coatCoarseness is 'Other' and otherCoatCoarseness is empty
    if (
      values.coatCoarseness === 'Other' &&
      !values.otherCoatCoarseness.trim()
    ) {
      validateForm(); // This will trigger form validation
    }
  }, [values.coatCoarseness, values.otherCoatCoarseness, validateForm]);

  const isButtonDisabled = () => {
    // Check if 'Other' is selected for coat texture or coarseness
    const isCoatTextureOtherInvalid =
      values.coatTexture === 'Other' && !values.otherCoatTexture.trim();

    const isCoatCoarsenessOtherInvalid =
      values.coatCoarseness === 'Other' && !values.otherCoatCoarseness.trim();

    return (
      !dirty ||
      !isValid ||
      isCoatTextureOtherInvalid ||
      isCoatCoarsenessOtherInvalid
    );
  };

  const handleCoatLengthChange = (option) => {
    setFieldValue('coatLength', option);

    // If 'Hairless' is selected, hide additional coat options
    if (option === 'Long' || option === 'Medium' || option === 'Short') {
      setShowAdditionalCoatOptions(true);
    } else if (option === 'Hairless') {
      setShowAdditionalCoatOptions(false);
      // Reset additional coat options when 'Hairless'
      setFieldValue('coatTexture', '');
      setFieldValue('coatCoarseness', '');
      setFieldValue('doubleCoat', '');
      setFieldValue('otherCoatTexture', '');
      setFieldValue('otherCoatCoarseness', '');
    }
  };

  const handleCoatTextureChange = (option) => {
    setFieldValue('coatTexture', option);
    setFieldTouched('coatTexture', true);
    if (option === 'Other') {
      setFieldTouched('otherCoatTexture', true);
    }
  };

  const handleOtherCoatTextureChange = (value) => {
    setFieldValue('otherCoatTexture', value);
    setFieldTouched('otherCoatTexture', true);
  };

  const handleCoatCoarsenessChange = (option) => {
    setFieldValue('coatCoarseness', option);
    setFieldTouched('coatCoarseness', true);
    if (option === 'Other') {
      setFieldTouched('otherCoatCoarseness', true);
    }
  };

  const handleOtherCoatCoarsenessChange = (value) => {
    setFieldValue('otherCoatCoarseness', value);
    setFieldTouched('otherCoatCoarseness', true);
  };

  const handleDoubleCoatChange = (option) => {
    setFieldValue('doubleCoat', option);
    setFieldTouched('doubleCoat', true);
  };

  return (
    <>
      <PrimaryHeader>{t('intake.dog-demographics.header')}</PrimaryHeader>
      <StyledGenericParagraph>{t('intake.questions')}</StyledGenericParagraph>
      <StyledWrapperWithErrorText>
        <FormElementLabel
          fieldFor="age"
          fieldId="age"
          labelText={t('intake.dog-demographics.age')}
        />
        <TextInput
          name="age"
          inputText="1.5"
          value={values.age}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={t('intake.dog-demographics.unknown')}
        />
      </StyledWrapperWithErrorText>
      <StyledWrapperWithErrorText>
        <FormElementLabel
          fieldFor="weight"
          fieldId="weight"
          labelText={t('intake.dog-demographics.weight')}
        />
        <TextInput
          name="weight"
          inputText="0.0"
          value={values.weight}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </StyledWrapperWithErrorText>
      <StyledWrapperWithErrorText>
        <FormElementLabel
          fieldFor="breed"
          fieldId="breed"
          labelText={t('intake.dog-demographics.breed')}
        />
        <TextInput
          name="breed"
          inputText={t('intake.dog-demographics.breed-placeholder')}
          value={values.breed}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </StyledWrapperWithErrorText>
      <StyledWrapperWithErrorText>
        <FormElementLabel
          fieldFor="coatColor"
          fieldId="coatColor"
          labelText={t('intake.dog-demographics.coat-color')}
        />
        <TextInput
          name="coatColor"
          inputText={t('intake.dog-demographics.coat-color-placeholder')}
          value={values.coatColor}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </StyledWrapperWithErrorText>
      <StyledWrapperWithErrorText>
        <FormElementLabel
          fieldFor="coatLength"
          fieldId="coatLength"
          labelText={t('intake.dog-demographics.coat-length')}
        />
        <DogCoatOptions
          options={t('intake.dog-demographics.coat-length-options', {
            returnObjects: true,
          })}
          onChange={handleCoatLengthChange}
          selectedOption={values.coatLength}
        />
        {touched.coatLength && errors.coatLength && (
          <ErrorText data-qa-id="intake--dog-demographics---coat-length-error">
            {errors.coatLength}
          </ErrorText>
        )}
      </StyledWrapperWithErrorText>
      {showAdditionalCoatOptions && (
        <>
          <StyledSectionWrapper>
            <FormElementLabel
              fieldFor="coatTexture"
              fieldId="coatTexture"
              labelText={t('intake.dog-demographics.coat-texture')}
            />
            <DogCoatOptions
              options={t('intake.dog-demographics.coat-texture-options', {
                returnObjects: true,
              })}
              onChange={handleCoatTextureChange}
              selectedOption={values.coatTexture}
              fullWidthOtherInput={true}
              name="otherCoatTexture"
              onOtherInputChange={handleOtherCoatTextureChange}
              otherInputValue={values.otherCoatTexture}
              otherInputPlaceholder={t(
                'intake.dog-demographics.coat-texture-placeholder',
              )}
            />
            {touched.coatTexture && errors.coatTexture && (
              <ErrorText data-qa-id="intake--dog-demographics---coat-texture-error">
                {errors.coatTexture}
              </ErrorText>
            )}
            {values.coatTexture === 'Other' && errors.otherCoatTexture && (
              <ErrorText data-qa-id="intake--dog-demographics---other-coat-texture-error">
                {errors.otherCoatTexture}
              </ErrorText>
            )}
          </StyledSectionWrapper>

          <StyledSectionWrapper>
            <FormElementLabel
              fieldFor="coatCoarseness"
              fieldId="coatCoarseness"
              labelText={t('intake.dog-demographics.coat-coarseness')}
            />
            <DogCoatOptions
              options={t('intake.dog-demographics.coat-coarseness-options', {
                returnObjects: true,
              })}
              onChange={handleCoatCoarsenessChange}
              selectedOption={values.coatCoarseness}
              fullWidthOtherInput={true}
              name="otherCoatCoarseness"
              onOtherInputChange={handleOtherCoatCoarsenessChange}
              otherInputValue={values.otherCoatCoarseness}
              otherInputPlaceholder={t(
                'intake.dog-demographics.coat-coarseness-placeholder',
              )}
            />
            {touched.coatCoarseness && errors.coatCoarseness && (
              <ErrorText data-qa-id="intake--dog-demographics---coat-coarseness-error">
                {errors.coatCoarseness}
              </ErrorText>
            )}
            {values.coatCoarseness === 'Other' &&
              errors.otherCoatCoarseness && (
                <ErrorText data-qa-id="intake--dog-demographics---other-coat-coarseness-error">
                  {errors.otherCoatCoarseness}
                </ErrorText>
              )}
          </StyledSectionWrapper>

          <StyledSectionWrapper>
            <FormElementLabel
              fieldFor="doubleCoat"
              fieldId="doubleCoat"
              labelText={t('intake.dog-demographics.coat-double')}
            />
            <DogCoatOptions
              options={t('intake.dog-demographics.double-coat-options', {
                returnObjects: true,
              })}
              onChange={handleDoubleCoatChange}
              selectedOption={values.doubleCoat}
            />
            {touched.doubleCoat && errors.doubleCoat && (
              <ErrorText data-qa-id="intake--dog-demographics---double-coat-error">
                {errors.doubleCoat}
              </ErrorText>
            )}
          </StyledSectionWrapper>
        </>
      )}

      <StyledSectionWrapper>
        <Button
          text={t('intake.continue-button')}
          onClick={onNext}
          isLoading={false}
          disabled={isButtonDisabled()}
          qaId="intake--dog-demographics-continue"
        />
      </StyledSectionWrapper>
    </>
  );
};

export default DogDemographics;
