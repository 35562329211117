import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

//If we add more languages, could be helpful to add the package:
//https://github.com/i18next/i18next-browser-languageDetector
//It helps with detecting user browser language

i18n
  // i18next-http-backend loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: false, //set this to true if you need to debug locally
  });

export default i18n;
