import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';

import DownAndBackForm from '../components/DownAndBackForm';
import ErrorModal from '../components/ErrorModal';
import ExpiredForm from '../components/ExpiredForm';
import ProgressBar from '../components/ProgressBar';
import SideToSideForm from '../components/SideToSideForm';
import SubmissionReceived from '../components/SubmissionReceived';
import SurveyTitle from '../components/SurveyTitle';
import VideoFormOverview from '../components/VideoFormOverview';
import api from '../utils/api';
import { errorByStatus } from '../utils/errors';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  @media (min-width: 1201px) {
    width: 50%;
  }
`;

const UI_STATES = {
  FORM_EXPIRED: 0,
  VIDEO_FORM_OVERVIEW: 1,
  DOWN_AND_BACK_FORM: 2,
  SIDE_TO_SIDE_FORM: 3,
  SUBMISSION_RECEIVED: 4,
};

const WeeklyForm = () => {
  const { weeklyFormId } = useParams();
  const [downAndBackId, setDownAndBackId] = useState(null);
  const [sideToSideId, setSideToSideId] = useState(null);
  const [UIState, setUIState] = useState(UI_STATES.VIDEO_FORM_OVERVIEW);
  const [error, setError] = useState(null);

  const { t } = useTranslation();

  const handleSetUIState = (newState) => {
    setUIState(newState);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [UIState]);

  useEffect(() => {
    const url = `weekly-forms/${weeklyFormId}`;

    const fetchWeeklyForm = async () => {
      try {
        const res = await api.get(url);
        if (res.data.data.skipped) {
          handleSetUIState(UI_STATES.FORM_EXPIRED);
        } else if (res.data.data.submitted_at) {
          handleSetUIState(UI_STATES.SUBMISSION_RECEIVED);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchWeeklyForm();
  }, [weeklyFormId]);

  const convertStringsToBools = (val) => {
    return val === 'Yes' ? true : false;
  };

  const handleSubmit = async (values) => {
    const url = `weekly-forms/${weeklyFormId}`;

    const data = {
      side_to_side_video: sideToSideId,
      down_and_back_video: downAndBackId,
      has_taken_pain_medication: convertStringsToBools(values.isOnPainMeds),
      has_taken_sedative: convertStringsToBools(values.isSedated),
      submitted_at: new Date(),
    };

    try {
      await api.patch(url, JSON.stringify({ data: data }));
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        // Timeout error
        setError(errorByStatus(408));
      } else if (!error.response && error.message === 'Network Error') {
        // Network error
        setError(errorByStatus(0));
      } else if (error.response?.status === 500) {
        // Server error
        setError(errorByStatus(500));
      } else {
        // all other errors
        setError(errorByStatus(null));
      }
    }
  };

  return (
    <StyledContainer>
      {error && (
        <ErrorModal
          title={error.title}
          message={error.message}
          onClose={() => setError(null)}
        />
      )}
      <SurveyTitle
        emoji="🐶"
        mainText={t('video-submission.study-name')}
        subtitleText={t('video-submission.study-subtitle')}
      />
      {UIState === UI_STATES.FORM_EXPIRED && <ExpiredForm />}
      {UIState !== UI_STATES.FORM_EXPIRED && (
        <ProgressBar currentstep={UIState} steps={4} />
      )}
      <Formik
        initialValues={{
          isOnPainMeds: '',
          isSedated: '',
        }}
        onSubmit={handleSubmit}
      >
        {() => (
          <>
            {UIState === UI_STATES.VIDEO_FORM_OVERVIEW && (
              <VideoFormOverview
                onNext={() => handleSetUIState(UI_STATES.DOWN_AND_BACK_FORM)}
              />
            )}
            {UIState === UI_STATES.DOWN_AND_BACK_FORM && (
              <DownAndBackForm
                setDownAndBackId={setDownAndBackId}
                onNext={() => {
                  handleSetUIState(UI_STATES.SIDE_TO_SIDE_FORM);
                }}
                setError={setError}
              />
            )}
            {UIState === UI_STATES.SIDE_TO_SIDE_FORM && (
              <SideToSideForm
                sideToSideId={sideToSideId}
                setSideToSideId={setSideToSideId}
                onNext={() => {
                  handleSetUIState(UI_STATES.SUBMISSION_RECEIVED);
                }}
                handleSubmit={handleSubmit}
                setError={setError}
              />
            )}
            {UIState === UI_STATES.SUBMISSION_RECEIVED && (
              <SubmissionReceived />
            )}
          </>
        )}
      </Formik>
    </StyledContainer>
  );
};

export default WeeklyForm;
