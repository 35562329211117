import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../styles/colors';
import typography from '../styles/typography';

const ListContainer = styled.ul`
  flex: 1 0 0;
  list-style-type: disc;
`;

const ListItem = styled.li`
  display: list-item;
  margin-bottom: 16px;
`;

const ListText = styled.p`
  color: ${colors.n100WetNose};
  ${typography.body};
  font-feature-settings:
    'liga' off,
    'clig' off;
  margin: 0;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UnorderedList = (props) => (
  <ListContainer>
    {props.items.map((item, index) => (
      <ListItem key={index}>
        <TextContainer>
          <ListText>{item}</ListText>
        </TextContainer>
      </ListItem>
    ))}
  </ListContainer>
);

UnorderedList.propTypes = {
  items: PropTypes.array.isRequired,
};

export default UnorderedList;
