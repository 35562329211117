import React from 'react';

import { Route, BrowserRouter as Router, Routes } from 'react-router';

import Contact from '../components/Contact';
import DogDemographics from '../components/DogDemographics';
import HealthStatus from '../components/HealthStatus';
import ThankYou from '../components/ThankYou';
import { ExpertReviewForm, ExpertSurveys, Intake, WeeklyForm } from '../pages';

export default (
  <Router>
    <Routes>
      <Route path="/" element={<Intake />} />
      <Route path="/intake" element={<Intake />}>
        <Route path="contact" element={<Contact />} />
        <Route path="dog-demographics" element={<DogDemographics />} />
        <Route path="health-status" element={<HealthStatus />} />
        <Route path="thank-you" element={<ThankYou />} />
      </Route>
      <Route path="/weekly-forms/:weeklyFormId" element={<WeeklyForm />} />
      <Route path="/surveys/:expertId" element={<ExpertSurveys />} />
      <Route
        path="/review/:surveyId/:weekNumber"
        element={<ExpertReviewForm />}
      />
    </Routes>
  </Router>
);
