import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../styles/colors';

const OuterContainer = styled.div`
  display: flex;
  height: 40px;
  padding: 20px 0;
  align-items: flex-start;
  align-self: stretch;
`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${colors.n20Sheepdog};
  border-radius: 2px;
`;

const Progress = styled.div`
  height: 100%;
  background-color: ${colors.coreBlueClue};
  width: ${({ currentstep, steps }) => (currentstep / steps) * 100}%;
  transition: width 0.3s ease;
  border-radius: 2px 0 0 2px; /* Rounds left end only */
`;

const ProgressBar = ({ steps, currentstep, qaId }) => (
  <OuterContainer data-qa-id={`${qaId}--progress-bar`}>
    <ProgressContainer>
      <Progress currentstep={currentstep} steps={steps} />
    </ProgressContainer>
  </OuterContainer>
);

ProgressBar.propTypes = {
  currentstep: PropTypes.number.isRequired,
  steps: PropTypes.number.isRequired,
  qaId: PropTypes.string.isRequired,
};

export default ProgressBar;
