import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../styles/colors';

const ButtonContainer = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${colors.n10Bone};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 12px;
  flex-shrink: 0;
`;

const IconImage = styled.img`
  width: 20px;
  height: 20px;
`;

const NavigationButton = (props) => (
  <ButtonContainer
    data-qa-id={`navigation-button--${props.iconType}`}
    onClick={props.onClick}
    bgColor={props.bgColor}
  >
    <IconImage
      src={props.iconSrc}
      alt={props.iconType === 'return' ? 'Go back' : 'Close'}
    />
  </ButtonContainer>
);

NavigationButton.propTypes = {
  bgColor: PropTypes.string,
  iconSrc: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NavigationButton;
