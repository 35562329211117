import React, { useCallback, useEffect, useState } from 'react';

import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router';
import styled, { css } from 'styled-components';

import Button from '../components/Button';
import DestructiveButton from '../components/DestructiveButton';
import ErrorModal from '../components/ErrorModal';
import GenericParagraph from '../components/GenericParagraph';
import NavigationButton from '../components/NavigationButton';
import OverlayModal from '../components/OverlayModal';
import ProgressBar from '../components/ProgressBar';
import SurveyTitle from '../components/SurveyTitle';
import colors from '../styles/colors';
import typography from '../styles/typography';
import api from '../utils/api';
import { errorByStatus } from '../utils/errors';
import {
  validateContact,
  validateDogDemographics,
  validateHealthStatus,
} from '../utils/intakeSurveyValidation';
import useActivityTimeout from '../utils/useActivityTimeout';

const ModalContainer = styled.div`
  display: flex;
  width: 335px;
  flex-direction: column;
  align-items: center;
`;

const StyledContainer = styled.div`
  background: ${colors.n00White};
  background-size: 40px 40px;
  background-position: -19px -19px;
  min-height: 100vh;
`;

const NavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledSurveyTitle = styled(SurveyTitle)`
  ${({ hideNavigationButtons }) =>
    !hideNavigationButtons &&
    css`
      margin-left: 20px;
    `}
`;

const StyledModalParagraph = styled(GenericParagraph)`
  margin: 12px 0 28px 0;
`;

const StyledModalTitle = styled.h1`
  ${typography.heading5With28LineHeight}
  font-family: UntitledSansMedium;
  margin: 0;
  padding: 0;
`;

const StyledModalButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const initialValues = {
  email: '',
  age: '',
  weight: '',
  breed: '',
  coatColor: '',
  coatLength: '',
  coatTexture: '',
  coatCoarseness: '',
  otherCoatTexture: '',
  otherCoatCoarseness: '',
  doubleCoat: '',
  mobilityIssues: [],
  otherMobilityDetails: '',
  scheduledSurgery: '',
  previousSurgeries: '',
  previousSurgeriesDetails: '',
  scheduledSurgeryDate: null,
};

const Intake = () => {
  const [submissionError, setSubmissionError] = useState(null);
  const [exitModalVisible, setExitModalVisible] = useState(false);
  const [completedSteps, setCompletedSteps] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const handleInactivity = useCallback(() => {
    formikRef.current?.resetForm();
    setCompletedSteps([]);
    navigate(ROUTES.CONTACT);
  }, [navigate]);

  useActivityTimeout(handleInactivity);

  const ROUTES = {
    CONTACT: '/intake/contact',
    DOG_DEMOGRAPHICS: '/intake/dog-demographics',
    HEALTH_STATUS: '/intake/health-status',
    THANK_YOU: '/intake/thank-you',
  };

  // To prevent users from manually skipping steps
  const isStepAccessible = (path) => {
    switch (path) {
      case ROUTES.CONTACT:
        return true;
      case ROUTES.DOG_DEMOGRAPHICS:
        return (
          completedSteps.includes(ROUTES.CONTACT) ||
          location.pathname === ROUTES.HEALTH_STATUS
        );
      case ROUTES.HEALTH_STATUS:
        return completedSteps.includes(ROUTES.DOG_DEMOGRAPHICS);
      case ROUTES.THANK_YOU:
        return completedSteps.includes(ROUTES.HEALTH_STATUS);
      default:
        return false;
    }
  };

  useEffect(() => {
    if (!isStepAccessible(location.pathname)) {
      navigate(ROUTES.CONTACT);
    }
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === '/intake') {
      navigate(ROUTES.CONTACT);
    }
  }, [location.pathname]);

  const formikRef = React.useRef();

  const hideNavigationButtons =
    location.pathname === ROUTES.CONTACT ||
    location.pathname === ROUTES.THANK_YOU;

  const stepValidation = (values) => {
    switch (location.pathname) {
      case ROUTES.CONTACT:
        return validateContact(values, t('intake.validations.email'));
      case ROUTES.DOG_DEMOGRAPHICS:
        return validateDogDemographics(
          values,
          t('intake.validations', { returnObjects: true }),
        );
      case ROUTES.HEALTH_STATUS:
        return validateHealthStatus(
          values,
          t('intake.validations', { returnObjects: true }),
        );
      case ROUTES.THANK_YOU:
        return {};
      default:
        return {};
    }
  };

  const pad = (num) => String(num).padStart(2, '0');

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      let formattedDate;

      if (values.scheduledSurgeryDate) {
        const date = new Date(values.scheduledSurgeryDate);
        // Format as YYYY-MM-DD
        formattedDate = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
      }

      const payload = {
        data: {
          email: values.email,
          surgery_date: formattedDate,
          dog_metadata: {
            age: Number(values.age), //convert to Number
            weight: Number(values.weight), //convert to Number
            breed: values.breed?.trim(),
            coat_color: values.coatColor?.trim(),
            coat_length: values.coatLength?.trim(),
            coat_texture: values.coatTexture?.trim(),
            coat_coarseness: values.coatCoarseness?.trim(),
            other_coat_texture: values.otherCoatTexture?.trim(),
            other_coat_coarseness: values.otherCoatCoarseness?.trim(),
            double_coat: values.doubleCoat === 'Yes', //convert to boolean
            mobility_issues: values.mobilityIssues,
            other_mobility_details: values.otherMobilityDetails?.trim(),
            scheduled_surgery: values.scheduledSurgery === 'Yes', //convert to boolean
            previous_surgeries: values.previousSurgeries === 'Yes', //convert to boolean
            previous_surgeries_details: values.previousSurgeriesDetails?.trim(),
          },
        },
      };

      const response = await api.post(`participants`, payload, {
        timeout: 5000, // 5 seconds
      });

      if (response.status === 200 || response.status === 201) {
        setSubmitting(false); //For making submit button disable
        navigate(ROUTES.THANK_YOU);
        resetForm();
        setCompletedSteps((prev) => [...prev, ROUTES.HEALTH_STATUS]);
      }
    } catch (error) {
      setSubmitting(false);
      if (error.code === 'ECONNABORTED') {
        // Timeout error
        setSubmissionError(errorByStatus(408));
      } else if (!error.response && error.message === 'Network Error') {
        // Network error
        setSubmissionError(errorByStatus(0));
      } else if (error.response?.status === 500) {
        // Server error
        setSubmissionError(errorByStatus(500));
      } else {
        // all other errors
        setSubmissionError(errorByStatus(null));
      }
    }
  };

  const getcurrentstep = () => {
    const currentPath = location.pathname;
    return Object.values(ROUTES).indexOf(currentPath) + 1;
  };

  const nextPage = () => {
    const currentPath = location.pathname;
    setCompletedSteps((prev) => [...prev, currentPath]);

    switch (currentPath) {
      case ROUTES.CONTACT:
        navigate(ROUTES.DOG_DEMOGRAPHICS);
        break;
      case ROUTES.DOG_DEMOGRAPHICS:
        navigate(ROUTES.HEALTH_STATUS);
        break;
      case ROUTES.HEALTH_STATUS:
        navigate(ROUTES.THANK_YOU);
        break;
    }
  };

  const previousPage = () => {
    const currentPath = location.pathname;
    switch (currentPath) {
      case ROUTES.DOG_DEMOGRAPHICS:
        // Keep CONTACT in completed steps when going back
        setCompletedSteps((prev) =>
          prev.filter((step) => step !== ROUTES.DOG_DEMOGRAPHICS),
        );
        navigate(ROUTES.CONTACT);
        break;
      case ROUTES.HEALTH_STATUS:
        // Keep DOG_DEMOGRAPHICS in completed steps when going back
        setCompletedSteps((prev) =>
          prev.filter((step) => step !== ROUTES.HEALTH_STATUS),
        );
        navigate(ROUTES.DOG_DEMOGRAPHICS);
        break;
    }
  };

  const openExitModal = () => {
    setExitModalVisible(true);
  };

  const exitForm = () => {
    formikRef.current?.resetForm();
    navigate('/intake');
    setExitModalVisible(false);
  };

  const handleEmailValidation = async (email, setFieldError) => {
    try {
      const response = await api.head(`participant-available`, {
        params: { email },
      });

      if (response.status === 200) {
        nextPage();
      }
    } catch (error) {
      if (!error.response) {
        // Network error or CORS issue
        setFieldError('email', t('intake.validations.network'));
      } else if (error.response.status === 400) {
        // Email already exists
        setFieldError('email', t('intake.validations.submitted-email'));
      } else {
        // Other server-side or unexpected error
        setFieldError('email', t('intake.validations.unknown'));
      }
    }
  };

  // Create a context object to pass down all needed functions and state
  const formContextValue = {
    onNext: async (values, setFieldError) => {
      if (location.pathname === ROUTES.CONTACT) {
        await handleEmailValidation(values.email, setFieldError);
        return true;
      } else {
        nextPage();
        return true;
      }
    },

    handleNewSurvey: () => {
      formikRef.current?.resetForm();
      setCompletedSteps([]);
      navigate(ROUTES.CONTACT);
    },

    // Add navigation functions
    nextPage,
  };

  return (
    <>
      <StyledContainer data-qa-id="intake--page">
        <NavigationWrapper>
          {!hideNavigationButtons && (
            <>
              <NavigationButton
                iconType="return"
                onClick={previousPage}
                iconSrc={window.imagePaths.iconArrowLeft}
              />
            </>
          )}
          <StyledSurveyTitle
            emoji="🐶"
            mainText={t('intake.study-name')}
            subtitleText={t('intake.study-subtitle')}
            hideNavigationButtons={hideNavigationButtons}
          />
          {!hideNavigationButtons && (
            <>
              <NavigationButton
                iconType="close"
                onClick={openExitModal}
                iconSrc={window.imagePaths.iconClose}
              />
            </>
          )}
        </NavigationWrapper>
        <ProgressBar
          qaId="intake"
          currentstep={getcurrentstep()}
          steps={Object.keys(ROUTES).length}
        />
        {submissionError && (
          <ErrorModal
            title={submissionError.title}
            message={submissionError.message}
            onClose={() => setSubmissionError(null)}
          />
        )}
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validate={stepValidation}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Outlet context={{ ...formikProps, ...formContextValue }} />
          )}
        </Formik>
      </StyledContainer>
      {exitModalVisible && (
        <OverlayModal>
          <ModalContainer>
            <StyledModalTitle>{t('intake.desired-exit')}</StyledModalTitle>
            <StyledModalParagraph>{t('intake.progress')}</StyledModalParagraph>
            <StyledModalButtonWrapper>
              <Button
                text="Cancel"
                height="52px"
                onClick={() => setExitModalVisible(false)}
              />
              <DestructiveButton
                text={t('intake.exit-form')}
                height="52px"
                onClick={exitForm}
                pressedBorderColor={colors.utilityFocus}
                spinnerImage={window.imagePaths.redStaticSpinner}
              />
            </StyledModalButtonWrapper>
          </ModalContainer>
        </OverlayModal>
      )}
    </>
  );
};

export default Intake;
