import i18next from 'i18next';

export const errorByStatus = (status) => {
  const stringifyStatus = status.toString();
  const errorObject = i18next.t('errors', { returnObjects: true });
  if (errorObject[stringifyStatus]) {
    return errorObject[stringifyStatus];
  } else {
    return errorObject['unknown'];
  }
};
