import React from 'react';

import { useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';

import PropTypes from 'prop-types';

import FormElementLabel from './FormElementLabel';

const StyledCalendar = styled.div`
  width: 350px;
  position: relative;
`;

const StyledSvg = styled.svg`
  position: absolute;
  right: 26px;
  z-index: 10;
  top: 46px;
`;

const Calendar = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  return (
    <StyledCalendar>
      <FormElementLabel
        labelText={t('components.calendar.label')}
        fieldFor="calendar"
        fieldId="calendar"
      />
      <StyledSvg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3332 18.3333H1.6665V2.5H6.0415V0.833332H7.2915V2.5H12.7082V0.833332H13.9582V2.5H18.3332V18.3333ZM17.0832 7.08333H2.9165V17.0833H17.0832V7.08333ZM6.0415 3.75H2.9165V5.83333H17.0832V3.75H13.9582V4.58333H12.7082V3.75H7.2915V4.58333H6.0415V3.75Z"
          fill="#101011"
        />
      </StyledSvg>
      <DatePicker
        selected={values.scheduledSurgeryDate}
        onChange={(date) => setFieldValue('scheduledSurgeryDate', date)}
        placeholderText={t('components.calendar.placeholder')}
        minDate={new Date()} // Disables past dates
        data-qa-id="intake--date-picker"
      />
    </StyledCalendar>
  );
};

Calendar.propTypes = {
  selectedDate: PropTypes.string.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
};

export default Calendar;
