import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import colors from '../styles/colors';
import typography from '../styles/typography';
import Button from './Button';
import OverlayModal from './OverlayModal';

const ButtonContainer = styled.div`
  display: flex;
  padding: 0px 12px;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  display: flex;
  height: 52px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
`;

const ModalImage = styled.img`
  width: 35px;
  height: 35px;
`;

const ModalTitle = styled.h3`
  color: ${colors.n100WetNose};
  ${typography.heading5With28LineHeight};
  margin: 0;
  text-align: center;
  max-width: 299px;
`;

const ModalMessage = styled.p`
  color: ${colors.n100WetNose};
  ${typography.body};
  margin: 0;
  text-align: center;
  max-width: 300px;
`;

const SupportLink = styled.a`
  color: ${colors.coreBlueClue};
  ${typography.bodyMedium};
  text-decoration: none;
`;

const ErrorModal = ({ title, message, onClose }) => {
  const { t } = useTranslation();

  const networkErrorTitle = t('errors.0.title');

  return (
    <OverlayModal onClick={onClose}>
      {title !== networkErrorTitle && (
        <ModalImage src={window.imagePaths.iconAlert} alt="Alert Icon" />
      )}
      <ModalTitle>{title}</ModalTitle>
      <ModalMessage>{message}</ModalMessage>
      <ModalMessage>
        {t('components.error-modal.issue-text')}
        <SupportLink
          href={`mailto:${t('components.error-modal.support-email')}`}
        >
          {t('components.error-modal.support-text')}
        </SupportLink>
      </ModalMessage>
      <ButtonContainer>
        <StyledButton text="Okay" onClick={onClose} disabled={false} />
      </ButtonContainer>
    </OverlayModal>
  );
};

ErrorModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ErrorModal;
