import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../styles/colors';
import styledWithPropsFiltered from '../styles/styled';
import typography from '../styles/typography';

const ButtonContainer = styledWithPropsFiltered('button')`
  display: inline-flex;
  height: ${({ height }) => (height ? height : '60px')};
  padding: 20px 40px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: ${({ bgColor }) => bgColor};
  border: ${({ isLoading, pressedBorderColor }) =>
    isLoading ? `2px solid ${pressedBorderColor}` : 'none'};
  cursor: ${({ isLoading, disabled }) =>
    isLoading || disabled ? 'not-allowed' : 'pointer'};

  &:hover {
    background-color: ${({ isLoading, disabled, hoverBgColor }) =>
      !isLoading && !disabled ? hoverBgColor : undefined};
  }

  &:active {
    border: ${({ isLoading, disabled, pressedBorderColor }) =>
      !isLoading && !disabled ? `2px solid ${pressedBorderColor}` : undefined};
  }
`;

const ButtonText = styledWithPropsFiltered('span')`
  ${typography.bodySmMedium};
  color: ${({ textColor }) => textColor};
  text-align: center;
  line-height: 20px;
  letter-spacing: 0.25px;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'visible')};
`;

const Icon = styled.img`
  margin-left: 10px;
`;

const SpinnerImage = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
`;

const ButtonContentWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Button = ({
  className = '',
  isLoading = false,
  disabled = false,
  bgColor = colors.coreBlueClue,
  onClick,
  hoverBgColor = colors.utilityPrimaryHover,
  pressedBorderColor = colors.utilityFocus,
  textColor = colors.n00White,
  text,
  icon = null,
  spinnerImage = window.imagePaths.whiteStaticSpinner,
  qaId,
  height = '60px',
}) => (
  <ButtonContainer
    className={className}
    onClick={!isLoading && !disabled ? onClick : undefined}
    bgColor={bgColor}
    hoverBgColor={hoverBgColor}
    pressedBorderColor={pressedBorderColor}
    isLoading={isLoading}
    disabled={disabled}
    data-qa-id={`${qaId}--button`}
    height={height}
  >
    <ButtonContentWrapper>
      <ButtonText
        textColor={textColor}
        isLoading={isLoading}
        disabled={disabled}
      >
        <span>
          {text}
          {icon && <Icon src={window.imagePaths[icon]} alt={icon} />}
        </span>
      </ButtonText>
      {isLoading && <SpinnerImage src={spinnerImage} alt="Loading..." />}
    </ButtonContentWrapper>
  </ButtonContainer>
);

Button.propTypes = {
  bgColor: PropTypes.string,
  disabled: PropTypes.bool,
  hoverBgColor: PropTypes.string,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  pressedBorderColor: PropTypes.string,
  spinnerImage: PropTypes.string,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  textColor: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  qaId: PropTypes.string,
};

export default Button;
