import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../styles/colors';
import typography from '../styles/typography';
import { formatQaId } from '../utils/formatters';

const ListContainer = styled.ul`
  padding: 0;
  flex: 1 0 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const BoldText = styled.p`
  color: ${colors.n100WetNose};
  ${typography.bodyMedium};
  font-feature-settings:
    'liga' off,
    'clig' off;
  margin: 0;
`;

const ParagraphText = styled.p`
  color: ${colors.n100WetNose};
  ${typography.body};
  font-feature-settings:
    'liga' off,
    'clig' off;
  margin: 0;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FancyUnorderedList = (props) => (
  <ListContainer>
    {props.items.map((item, index) => (
      <ListItem
        key={index}
        data-qa-id={`intake--unordered-list-${formatQaId(item.title)}`}
      >
        <Icon src={window.imagePaths.iconArrowRight} alt="Arrow icon" />
        <TextContainer>
          <BoldText>{item.title}</BoldText>
          <ParagraphText>{item.description}</ParagraphText>
        </TextContainer>
      </ListItem>
    ))}
  </ListContainer>
);

FancyUnorderedList.propTypes = {
  items: PropTypes.array.isRequired,
};

export default FancyUnorderedList;
