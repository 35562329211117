import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../styles/colors';
import typography from '../styles/typography';
import CloseIcon from './CloseIcon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  margin-bottom: auto;
`;

const Video = styled.video`
  object-fit: cover;
  width: 318px;
  height: 180px;
`;

const GreyBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 0;
`;

const FileName = styled.p`
  font-size: ${typography.bodySm};
  color: ${colors.n100WetNose};
  margin: 0 0 0 15px;
`;

const ExitButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding-right: 15px;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const VideoPreview = ({ preview, file, onChange }) => {
  const video = React.useRef();

  useEffect(() => {
    video.current?.pause(); //pausing video for preview
  }, []);

  return (
    <Container data-qa-id="video-submission--video-preview">
      <Video
        ref={video}
        playsInline
        autoPlay={true} //This forces video to show in mobile
        muted={true}
        // Revoke data uri after image is loaded
        onLoad={() => {
          URL.revokeObjectURL(preview);
        }}
      >
        <source src={preview + '#t=0.001'} />
        <source src={window.imagePaths.videoBackup} type="video/mp4" />
      </Video>
      <GreyBar>
        <FileName>{file.name}</FileName>
        <ExitButton
          onClick={onChange}
          data-qa-id="video-submission--exit-preview"
        >
          <CloseIcon fill={colors.n100WetNose} />
        </ExitButton>
      </GreyBar>
    </Container>
  );
};

VideoPreview.propTypes = {
  preview: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default VideoPreview;
