import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../styles/colors';
import typography from '../styles/typography';

const AdditionalInfoContainer = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  background: ${colors.secondaryWaterBowl};
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const AdditionalInfoText = styled.p`
  ${typography.body};
  color: #000;
  flex: 1 0 0;
  margin: 0;
  padding: 0;
`;

const AdditionalInfo = ({ className, additionalInfoText, icon }) => (
  <AdditionalInfoContainer className={className}>
    {icon && <Icon src={window.imagePaths[icon]} alt={icon} alert={icon} />}
    <AdditionalInfoText>{additionalInfoText}</AdditionalInfoText>
  </AdditionalInfoContainer>
);

AdditionalInfo.propTypes = {
  className: PropTypes.string,
  additionalInfoText: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default AdditionalInfo;
