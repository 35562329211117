import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router';
import styled from 'styled-components';

import { formatQaId } from '../utils/formatters';
import Button from './Button';
import Calendar from './Calendar';
import Checkbox from './Checkbox';
import ErrorText from './ErrorText';
import ExpandableTextbox from './ExpandableTextbox';
import FormElementLabel from './FormElementLabel';
import GenericParagraph from './GenericParagraph';
import PrimaryHeader from './PrimaryHeader';
import RadioButton from './RadioButton';
import Subtext from './Subtext';

const StyledGenericParagraph = styled(GenericParagraph)`
  margin: 35px 0;
`;

const StyledFormElementLabel = styled(FormElementLabel)`
  margin: 0;
`;

const StyledSubtext = styled(Subtext)`
  margin: 0 0 10px 0;
`;

const StyledSectionWrapper = styled.div`
  margin-top: 45px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExpandableTextboxWrapper = styled.div`
  margin-left: 40px;
  margin-top: 8px;
`;

const HealthStatus = () => {
  const formikProps = useOutletContext();
  const { t } = useTranslation();
  const {
    handleChange,
    handleBlur,
    values,
    isValid,
    dirty,
    validateForm,
    setFieldValue,
    errors,
    touched,
    isSubmitting,
    submitForm,
  } = formikProps;

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const handleCheckboxChange = (label, isChecked) => {
    const updatedMobilityIssues = isChecked
      ? [...(values.mobilityIssues || []), label]
      : (values.mobilityIssues || []).filter((issue) => issue !== label);

    handleChange({
      target: {
        name: 'mobilityIssues',
        value: updatedMobilityIssues,
      },
    });
  };

  const CheckboxList = [
    {
      label: t('intake.health-status.ccl-tear', { direction: 'Left' }),
      checked: false,
    },
    {
      label: t('intake.health-status.ccl-tear', { direction: 'Right' }),
      checked: false,
    },
    { label: t('intake.health-status.arthritis'), checked: false },
    {
      label: t('intake.health-status.other-mobility'),
      checked: false,
    },
  ];

  return (
    <>
      <PrimaryHeader>{t('intake.health-status.header')}</PrimaryHeader>
      <StyledGenericParagraph>{t('intake.questions')}</StyledGenericParagraph>
      <StyledFormElementLabel
        fieldFor="mobility"
        fieldId="mobility"
        labelText={t('intake.health-status.mobility-issues')}
      />
      <StyledSubtext>{t('intake.health-status.select')}</StyledSubtext>
      {CheckboxList.map((item, index) => (
        <CheckboxWrapper key={index}>
          <Checkbox
            defaultChecked={values.mobilityIssues?.includes(item.label)}
            label={item.label}
            qaId={formatQaId(item.label)}
            onChange={(e) => handleCheckboxChange(item.label, e.target.checked)}
          />
          {item.label === 'Other mobility issue' &&
            values.mobilityIssues?.includes('Other mobility issue') && (
              <ExpandableTextboxWrapper>
                <ExpandableTextbox
                  name="otherMobilityDetails"
                  placeholder={t(
                    'intake.health-status.other-issue-placeholder',
                  )}
                  value={values.otherMobilityDetails}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  qaId={'other-mobility-details'}
                />
                {touched.otherMobilityDetails &&
                  errors.otherMobilityDetails && (
                    <ErrorText data-qa-id={`${formatQaId(item.label)}-error`}>
                      {errors.otherMobilityDetails}
                    </ErrorText>
                  )}
              </ExpandableTextboxWrapper>
            )}
        </CheckboxWrapper>
      ))}
      <StyledSectionWrapper>
        <FormElementLabel
          fieldFor="scheduledSurgery"
          fieldId="scheduledSurgery"
          labelText={t('intake.health-status.surgery-scheduled')}
        />
        <RadioButton
          name="scheduledSurgery"
          value={values.scheduledSurgery}
          onChange={(value) => setFieldValue('scheduledSurgery', value)}
          qaId={'intake--scheduled-surgery'}
        />
        {values.scheduledSurgery === 'Yes' && (
          <StyledSectionWrapper>
            <Calendar />
          </StyledSectionWrapper>
        )}
      </StyledSectionWrapper>
      <StyledSectionWrapper>
        <FormElementLabel
          fieldFor="previousSurgeries"
          fieldId="previousSurgeries"
          labelText={t('intake.health-status.related-surgeries')}
        />
        <RadioButton
          name="previousSurgeries"
          value={values.previousSurgeries}
          onChange={(value) => setFieldValue('previousSurgeries', value)}
          qaId={'intake--previous-surgeries'}
        />
        {values.previousSurgeries === 'Yes' && (
          <StyledSectionWrapper>
            <FormElementLabel
              fieldFor="previousSurgeriesTextbox"
              fieldId="previousSurgeriesTextbox"
              labelText={t('intake.health-status.previous-surgeries')}
            />
            <ExpandableTextbox
              name="previousSurgeriesDetails"
              placeholder={t('intake.health-status.list-previous-surgeries')}
              value={values.previousSurgeriesDetails}
              onChange={handleChange}
              onBlur={handleBlur}
              qaId="previous-surgeries"
            />
            {touched.previousSurgeriesDetails &&
              errors.previousSurgeriesDetails && (
                <ErrorText>{errors.previousSurgeriesDetails}</ErrorText>
              )}
          </StyledSectionWrapper>
        )}
      </StyledSectionWrapper>
      <StyledSectionWrapper>
        <Button
          text={t('intake.health-status.submit-button')}
          onClick={submitForm}
          isLoading={isSubmitting}
          disabled={!isValid || !dirty || isSubmitting}
          qaId="intake--submit"
        />
      </StyledSectionWrapper>
    </>
  );
};

export default HealthStatus;
