import React from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import typography from '../styles/typography';
import AdditionalInfo from './AdditionalInfo';
import GenericParagraph from './GenericParagraph';
import PrimaryHeader from './PrimaryHeader';

const StyledHeader = styled(PrimaryHeader)`
  margin-top: 5px;
`;

const Alert = styled(AdditionalInfo)`
  border-radius: 0;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 15px;
  margin-top: 10px;

  p {
    ${typography.bodyMedium}
  }
`;

const BoldText = styled.span`
  ${typography.bodyMedium}
`;

const ExpiredForm = () => {
  const { t } = useTranslation();
  return (
    <div data-qa-id="video-submission--expired-form">
      <StyledHeader>{t('video-submission.form-expired.header')}</StyledHeader>
      <Alert
        icon="iconAlertCircle"
        additionalInfoText={t('video-submission.form-expired.alert')}
      />
      <GenericParagraph>
        {t('video-submission.form-expired.description')}
      </GenericParagraph>
      <GenericParagraph>
        {t('video-submission.form-expired.contact')}
        <BoldText>{t('video-submission.form-expired.email')}</BoldText>.
      </GenericParagraph>
      <GenericParagraph>
        {t('video-submission.form-expired.thank-you')}
      </GenericParagraph>
    </div>
  );
};

export default ExpiredForm;
