const fonts = {
  UntitledSans: 'UntitledSans',
  UntitledSerif: 'UntitledSerif',
  UntitledSansMedium: 'UntitledSansMedium',
  UntitledSerifMedium: 'UntitledSerifMedium',
};
// Note: For bold text, we need to use the UntitledSansMedium as font-family

const header = {
  fontFamily: fonts.UntitledSerif,
  fontSize: '21px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '1px',
};

const headerMedium = {
  fontFamily: fonts.UntitledSerifMedium,
  fontSize: '21px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '1px',
};
const heading3 = {
  fontFamily: fonts.UntitledSans,
  fontSize: '38px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '48px',
  letterSpacing: '-0.5px',
};

const heading5With28LineHeight = {
  fontFamily: fonts.UntitledSans,
  fontSize: '21px',
  fontStyle: 'normal',
  lineHeight: '28px',
};

const heading5With32LineHeight = {
  fontFamily: fonts.UntitledSans,
  fontSize: '21px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '32px',
};

const body = {
  fontFamily: fonts.UntitledSans,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.1px',
};

const bodyMedium = {
  fontFamily: fonts.UntitledSansMedium,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.1px',
};

const bodySmMedium = {
  fontFamily: fonts.UntitledSansMedium,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.1px',
};

const bodySm = {
  fontFamily: fonts.UntitledSans,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.25px',
};

const bodyXSm = {
  fontFamily: fonts.UntitledSans,
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.4px',
};

export default {
  fonts,
  header,
  headerMedium,
  heading3,
  heading5With28LineHeight,
  heading5With32LineHeight,
  body,
  bodyMedium,
  bodySmMedium,
  bodySm,
  bodyXSm,
};
