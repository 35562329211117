import React from 'react';

import PropTypes from 'prop-types';

import colors from '../styles/colors';

const CloseIcon = ({
  width = 16,
  height = 16,
  fill = `${colors.n00White}`,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3138 2.97978L13.0209 3.68689L8.70714 7.99978L13.0209 12.3131L12.3138 13.0202L8.00047 8.70645L3.68758 13.0202L2.98047 12.3131L7.2938 7.99978L2.98047 3.68689L3.68758 2.97978L8.00047 7.29312L12.3138 2.97978Z"
      fill={fill}
    />
  </svg>
);

CloseIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default CloseIcon;
