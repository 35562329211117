import React, { useState } from 'react';

import { useField } from 'formik';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import colors from '../styles/colors';
import typography from '../styles/typography';
import Subtext from './Subtext';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 12px;
  align-items: flex-start;
  align-self: stretch;
  background: ${colors.n10Bone};
  border: 1px solid ${colors.n40Wolf};
  box-shadow: none;
  transition:
    box-shadow 0.2s,
    border-color 0.2s;

  ${({ isFocused }) =>
    isFocused &&
    css`
      border-color: ${colors.utilityFocus};
      box-shadow: 0 0 0 3px ${colors.utilityFocus};
    `}

  ${({ showError }) =>
    showError &&
    css`
      border-color: ${colors.communicationDanger};
      box-shadow: 0 0 0 1px ${colors.communicationDanger};
    `}
`;

const StyledInput = styled.input`
  flex: 1;
  color: ${colors.n100WetNose};
  ${typography.body};
  font-feature-settings:
    'liga' off,
    'clig' off;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  padding: 0;
`;

const ErrorSubtext = styled(Subtext)`
  color: ${colors.communicationDanger};
  margin-top: 4px;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  height: 16px;
  line-height: 16px;
`;

const StyledSubtext = styled(Subtext)`
  margin: 0 0 0 12px;
`;

const TextInput = ({ inputText, helperText, ...props }) => {
  const [field, meta] = useField(props);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e) => {
    setIsFocused(false);
    field.onBlur(e);
  };

  const showError = !!meta.error && meta.touched && !isFocused;

  return (
    <OuterContainer>
      <TextInputContainer isFocused={isFocused} showError={showError}>
        <StyledInput
          {...field}
          type="text"
          placeholder={inputText}
          onFocus={handleFocus}
          onBlur={handleBlur}
          data-qa-id={`text-input-${props.name}`}
        />
      </TextInputContainer>
      {helperText && <StyledSubtext>{helperText}</StyledSubtext>}
      <ErrorSubtext
        show={showError}
        data-qa-id={`text-input-${props.name}--error`}
      >
        {meta.error}
      </ErrorSubtext>
    </OuterContainer>
  );
};

TextInput.propTypes = {
  inputText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
};

TextInput.defaultProps = {
  helperText: null,
};

export default TextInput;
