import styled from 'styled-components';

import mediaQueries from '../styles/layout';
import typography from '../styles/typography';

const PrimaryHeader = styled.h1`
  ${mediaQueries.forMobile} {
    font-size: 28px;
    line-height: 40px;
    margin: 40px 0 15px 0;
  }
  ${typography.heading3}
  text-align: left;
  margin: 40px 0 35px 0;
`;

export default PrimaryHeader;
