import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../styles/colors';
import typography from '../styles/typography';

const ElementContainer = styled.div`
  align-self: stretch;
  margin-bottom: 8px;
  /* In most cases, we’ll need this space, so let’s put it here instead of adding it everywhere. If it needs to be removed, it can be overridden with styled-components while using it. */
`;

const LabelText = styled.label`
  color: ${colors.n100WetNose};
  ${typography.bodySmMedium};
  font-feature-settings:
    'liga' off,
    'clig' off;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

const FormElementLabel = ({ className, labelText, fieldFor, fieldId }) => (
  <ElementContainer className={className}>
    <LabelText htmlFor={fieldFor} id={fieldId}>
      {labelText}
    </LabelText>
  </ElementContainer>
);

FormElementLabel.propTypes = {
  className: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  fieldFor: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
};

export default FormElementLabel;
