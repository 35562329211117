import styled from 'styled-components';

import mediaQueries from '../styles/layout';
import typography from '../styles/typography';

const SubjectText = styled.h2`
  ${mediaQueries.forMobile} {
    font-size: 16px;
    line-height: 24px;
  }
  ${typography.heading5With32LineHeight}
  text-align: left;
`;

export default SubjectText;
