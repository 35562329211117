import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import colors from '../styles/colors';
import typography from '../styles/typography';
import VideoPreview from './VideoPreview';

const Container = styled.div`
  background: ${colors.n20Sheepdog};
  border: 1px dashed ${colors.n40Wolf};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 320px;
  height: ${(props) => props.theme.height};
  padding: ${(props) => props.theme.padding};
`;

const DropzoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 17px;
`;

const BrowseButton = styled.button`
  background: ${colors.n00White};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 24px;
  border: none;
  img {
    width: 20px;
    height: 20px;
  }
`;

const MobileText = styled.p`
  color: ${colors.n100WetNose};
  ${typography.bodyMedium}
  font-size: 14px;
  margin: 0;
  display: none;
  @media (max-width: 1023px) {
    display: inline;
  }
`;

const DesktopText = styled.div`
  p,
  button {
    font-size: ${typography.bodySm};
  }
  p {
    color: ${colors.n100WetNose};
    margin: 0;
  }
  button {
    cursor: pointer;
    color: ${colors.coreBlueClue};
    border: none;
    background: none;
    ${typography.bodyMedium}
    font-size: 14px;
  }
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
`;

const VideoUpload = ({ file, setFile, setError }) => {
  const [preview, setPreview] = useState(false);
  const [displayPreview, setDisplayPreview] = useState(false);

  const { t } = useTranslation();

  const fileValidator = (code, message) => {
    switch (code) {
      case 'file-too-large':
        return t('components.video-upload.errors.large');
      case 'file-invalid-type':
        return t('components.video-upload.errors.type');
      case 'too-many-files':
        return t('components.video-upload.errors.too-many');
      default:
        return message;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      //this seems obnoxious to have to list them out, but if some of the obscure formats aren't explicitly listed then they don't work
      'video/*': [
        '.mjpeg',
        '.hevc',
        '.m2v',
        '.flv',
        '.mkv',
        '.vob',
        '.rm',
        '.ts',
        '.wtv',
        '.f4v',
        '.m2ts',
        '.mxf',
        '.avi',
        '.wmv',
        '.mpeg',
        '.ogv',
        '.asf',
        '.3gp',
        '.m2ts',
        '.m4v',
        '.mov',
        '.mp4',
        '.mpg',
        '.mts',
        '.ogv',
        '.rm',
        '.swf',
        '.webm',
      ],
    },
    maxFiles: 1,
    minSize: 0,
    maxSize: 367001600, //capping accepted size at 350mb
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length > 0) {
        setFile(acceptedFiles[0]);
        setPreview(URL.createObjectURL(acceptedFiles[0]));
        setDisplayPreview(true);
      }

      if (fileRejections.length > 0) {
        const fileRejectionItems = fileRejections[0]?.errors?.map((e) =>
          fileValidator(e.code, e.message),
        );

        setError({
          title: t('components.video-upload.errors.title'),
          message:
            t('components.video-upload.errors.message') + fileRejectionItems,
        });
      }
    },
  });

  const handleClearPreview = () => {
    setDisplayPreview(false);
    setFile(0);
  };

  return (
    <Container
      className="container"
      theme={{
        padding: displayPreview ? '0' : '40px 36px',
        height: displayPreview ? 'fit-content' : '180px',
      }}
    >
      {!displayPreview ? (
        <DropzoneContainer {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <BrowseButton data-qa-id="video-submission--video-upload-plus--button">
            <img src={window.imagePaths.iconPlus} alt="plus icon" />
          </BrowseButton>
          <MobileText>{t('components.video-upload.mobile-text')}</MobileText>
          <DesktopText>
            <p>{t('components.video-upload.desktop-drag')}</p>
            <button data-qa-id="video-submission--video-upload-browse--button">
              {t('components.video-upload.desktop-browse')}
            </button>
          </DesktopText>
        </DropzoneContainer>
      ) : (
        <VideoPreview
          file={file}
          preview={preview}
          onChange={handleClearPreview}
        />
      )}
    </Container>
  );
};

VideoUpload.propTypes = {
  file: PropTypes.object.isRequired,
  setFile: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

export default VideoUpload;
