import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../styles/colors';
import typography from '../styles/typography';

const Input = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  height: 24px;
  width: 24px;
  min-width: 24px;
  background-color: ${colors.n20Sheepdog};
  border: 1px solid ${colors.n40Wolf};
  position: relative;
  border-radius: 4px;
  cursor: pointer;

  &:checked {
    background-color: ${colors.coreBlueClue};
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10 17L5 12L6.41 10.59L10 14.17L17.59 6.57999L19 7.99999L10 17Z" fill="white"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:checked::after {
    opacity: 1;
  }
`;

const Label = styled.label`
  height: 28px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  margin-top: 8px;
`;

const LabelText = styled.p`
  ${typography.body}
  color: ${colors.n100WetNose};
  margin: 0px;
  padding: 0px;
  align-self: end;
`;

const Checkbox = ({ label, checked, onChange, qaId, ...props }) => {
  return (
    <React.Fragment>
      <Label>
        <Input
          data-qa-id={`${qaId}--checkbox`}
          checked={checked}
          onChange={onChange}
          {...props}
        />
        <LabelText>{label}</LabelText>
      </Label>
    </React.Fragment>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  qaId: PropTypes.string.isRequired,
};

export default Checkbox;
