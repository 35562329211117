import styled from 'styled-components';

import typography from '../styles/typography';

const Subtext = styled.h3`
  ${typography.bodyXSm}
  margin-left:12px;
`;

export default Subtext;
